
<script setup lang="ts">

const { page, toc } = useContent()

const emit = defineEmits(['move'])

const isAPISidebar = page.value?.sidebar == 'API'

const tocTitle = computed(
  () => page.value?.toc_title
)

</script>

<template>
  <div class="flex flex-col space-y-1 sm:space-y-2">
    <template v-if="toc?.links?.length">
      <div class="items-center hidden overflow-hidden text-sm font-semibold lg:flex">
        <span>{{ tocTitle || 'Table of Contents' }}</span>
      </div>

      <DocsTocLinksAPI v-if="isAPISidebar" :links="toc.links" @move="emit('move')" />
      <DocsTocLinks v-else :links="toc.links" @move="emit('move')" />
    </template>
  </div>
</template>
