export const getRelatedArticles = async (page) => {
    if (!page?.article) { return [] }
    if (page?.article?.related === false) { return [] }
    if (page.article?.related?.length > 0) {
        return await queryContent().only(['_path', 'title', 'article', 'author', 'redirection']).where({ _path: { $in: page.article.related } }).find()
    }

    const [primary, ...tags] = page.article?.tags

    const relatedQueryByKeywords = queryContent().only(['_path', 'title', 'article', 'author', 'redirection']).sort({ 'article.date': 1 }).where(
        {
            _path: { $regex: /^\/(news|blog|developers\/tutorials|developers\/showcases)\//, $ne: page._path },
            'keywords': { $in: page?.keywords }
        }).limit(3)

    const relatedQuery = queryContent().only(['_path', 'title', 'article', 'author', 'redirection']).sort({ 'article.date': 1 }).where(
        {
            _path: { $regex: /^\/(news|blog|developers\/tutorials|developers\/showcases)\//, $ne: page._path },
            'article.tags': { $in: tags }
        }).limit(3)

    const unrelatedQuery = queryContent().only(['_path', 'title', 'article', 'author', 'redirection']).where(
        {
            _path: { $regex: /^\/(news|blog|developers\/tutorials|developers\/showcases)\//, $ne: page._path },
            'article.tags': { $in: primary }
        }).limit(3)

    const { data: related } = await useAsyncData('equal', async () => {
        const [relatedByKeywords, relatedArticles, unrelatedArticles] = await Promise.all([
            relatedQueryByKeywords.find(),
            relatedQuery.find(),
            unrelatedQuery.find()
        ])
        return relatedByKeywords.concat(relatedArticles.concat(unrelatedArticles).slice(0, 3)).slice(0, 3)
    });
    return related

}
